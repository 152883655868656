import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import ThriftyAdviceWrapper from "@components/layouts/thriftyAdviceWrapper";
import MainCategoriesWrapper from "@components/thriftyadvice/mainCategoryWrapper";
export default function ThriftyAdviceHome(): ReactElement {
    return (
        <ThriftyAdviceWrapper>
            <MainCategoriesWrapper />
        </ThriftyAdviceWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home", { isStaticContent: true });
};
