import { ReactElement, ReactNode } from "react";
import styles from "./style.module.scss";

export default function SharedButton({
    children,
    href,
}: {
    children: ReactNode;
    href: string;
}): ReactElement {
    return (
        <a
            href={href}
            target="_blank"
            className={`py-4 sm:px-16 px-4 sm:text-lg font-semibold rounded-[13px] ${styles["animation"]} block text-center`}
            rel="noreferrer"
        >
            {children}
        </a>
    );
}
