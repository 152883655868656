import React, { ReactElement } from "react";
import Image from "next/image";
import SharedButton from "../sharedButton";
import styles from "./style.module.scss";
export default function MainCategoryComponent({
    step,
}: {
    step: {
        title: string;
        img: string;
        buttonTitle: string;
        content: JSX.Element | string;
        href: string;
    };
}): ReactElement {
    const { title, img, buttonTitle, content, href } = step;
    return (
        <section className="lg:mt-28 mt-14">
            <h2 className="font-bold lg:text-4xl text-2xl lg:leading-[45px] text-[#39A7AF] ">
                {title}
            </h2>
            <div className={styles["contentStyle"]}>{content}</div>
            <div className="relative mt-7">
                <Image
                    src={img}
                    alt="Image"
                    layout="responsive"
                    width={900}
                    height={504}
                />
            </div>
            <div className="mt-10 md:max-w-[600px]  mx-auto">
                <SharedButton href={href}>{buttonTitle}</SharedButton>
            </div>
        </section>
    );
}
