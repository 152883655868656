import React, { ReactElement } from "react";
import Image from "next/image";
import { monthsArr } from "@lib/sharedUtils";
import MainCategoryComponent from "../mainCategoryComponent";
import { useRouter } from "next/router";
import { gettingQueriesAsString } from "@lib/shared/routerQueriesToString";

export default function MainCategoriesWrapper(): ReactElement {
    const { query } = useRouter();

    const queryString = gettingQueriesAsString(query, "start");
    const mainCategoriesArray = [
        {
            title: "1. Understand Your Debt",
            img: "/thriftyadvice/assets/images/understandYourDebtSituation.jpg",
            content: (
                <>
                    <p>
                        Let's start by shedding light on your debt situation.
                        Take a moment to relax, grab your financial statements,
                        and sit down with a warm cup of coffee. It's time to
                        calculate the full extent of your debts, from those
                        persistently nagging credit card balances to the looming
                        mortgage. Pay attention to the interest rates, as they
                        are the silent accomplices of your financial
                        difficulties. Now,{" "}
                        <a
                            href={`https://debtreliefoffice.com/generic/find-out-the-minimum-monthly-payment-of-your-debt${
                                queryString ? `?${queryString}` : ""
                            }`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            tally up the minimum monthly payments.
                        </a>
                        .
                    </p>
                    <p>
                        You're now armed with a clear picture of your debt
                        battlefield. Ready to face your{" "}
                        <a
                            href={`https://debtreliefoffice.com/generic/find-out-the-minimum-monthly-payment-of-your-debt${
                                queryString ? `?${queryString}` : ""
                            }`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            debt head-on
                        </a>{" "}
                        ?
                    </p>
                </>
            ),
            buttonTitle: "Analyze My Debt",
            href: `https://debtreliefoffice.com/generic/find-out-the-minimum-monthly-payment-of-your-debt${
                queryString ? `?${queryString}` : ""
            }`,
        },
        {
            title: "2. Explore Debt Relief Options",
            img: "/thriftyadvice/assets/images/exploringDebtReliefOptions.jpg",
            content: (
                <>
                    <p>
                        Seniors have a wealth of debt relief options at their
                        fingertips. Debt consolidation, debt settlement, and
                        yes, even the "B" word (bankruptcy) may be in your
                        arsenal. But remember, knowledge is power. Explore these
                        options meticulously, and don't hesitate to seek
                        professional advice. What works best for you will depend
                        on your unique financial situation.
                    </p>
                    <p>
                        Ready to explore your debt relief options with a
                        seasoned guide by your side? Our experienced advisors
                        are just a phone call away.{" "}
                        <a
                            href={`https://debtreliefoffice.com/generic/accredited-debt-relief-advisors-around-me${
                                queryString ? `?${queryString}` : ""
                            }`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Reach out today
                        </a>{" "}
                        to start your personalized journey to financial freedom.
                    </p>
                </>
            ),
            buttonTitle: "What Are My Debt Relief Options?",
            href: `https://debtreliefoffice.com/generic/accredited-debt-relief-advisors-around-me${
                queryString ? `?${queryString}` : ""
            }`,
        },
        {
            title: "3. Discover Special Tax Relief Programs for Seniors",
            img: "/thriftyadvice/assets/images/taxDebtRelief.jpg",
            content: (
                <>
                    <p>
                        Tax debt can feel like an anchor, weighing down your
                        financial stability in retirement. However, there is
                        hope on the horizon. Tax relief strategies are
                        specifically designed to assist seniors in alleviating
                        their financial burdens. These customized strategies
                        offer a much-needed respite from the stress of dealing
                        with tax debt.
                    </p>
                    <p>
                        <a
                            href={`https://taxdebtoffers.com/generic/discover-special-tax-relief-programs-for-seniors${
                                queryString ? `?${queryString}` : ""
                            }`}
                        >
                            Explore your options
                        </a>{" "}
                        to help reduce, and in some cases eliminate, tax issues,
                        ranging from filing or correcting a tax return to
                        arrangements like penalty abatement, installment
                        agreements, or offers in compromise.
                    </p>
                </>
            ),
            buttonTitle: "Discover Tailored Tax Debt Solutions for Seniors",
            href: `https://taxdebtoffers.com/generic/customized-tax-relief-strategies-for-seniors${
                queryString ? `?${queryString}` : ""
            }`,
        },
        {
            title: "4. Stay Ahead of Costly Home Repair Bills: The Warranty Way",
            img: "/thriftyadvice/assets/images/homeRepairBills.jpg",
            content: (
                <>
                    <p>
                        When crucial appliances take an unplanned break, it's
                        not just about the inconvenience. It's the frantic
                        search for reliable technicians and the unexpected dent
                        in your wallet. Most home insurance policies step back
                        right when you wish they'd step up.
                    </p>
                    <p>
                        But here's the modern homeowner's edge:{" "}
                        <a
                            href={`https://homewarrantyforless.com/generic/unlock-big-savings-with-tailored-home-warranty${
                                queryString ? `?${queryString}` : ""
                            }`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Home Warranty for Less.
                        </a>{" "}
                        More than just a policy, it's peace of mind on standby.
                        It sidesteps DIY mishaps, technician hunting, and
                        surprise costs. Whether it's swift appliance
                        replacements or boosting your home's future resale
                        value, this warranty has your back. Your home, your
                        rules, minus the unpredictability
                    </p>
                </>
            ),
            buttonTitle: "Start Saving Thousands on Home Repairs",
            href: `https://homewarrantyforless.com/generic/save-thousands-on-home-repairs${
                queryString ? `?${queryString}` : ""
            }`,
        },
        {
            title: "5. Unexpected Car Breakdowns: The Hidden Cost You Can't Afford to Overlook",
            img: "/thriftyadvice/assets/images/hiddenCostManyOverlook.jpg",
            content: (
                <>
                    <p>
                        For most car owners, especially those with vehicles that
                        have seen their share of miles, unexpected repair bills
                        hit the hardest. As our cars age, the need for repairs
                        becomes more frequent, and the costs only seem to climb.
                        While diligent car owners might keep up with regular
                        maintenance, they may be overlooking the{" "}
                        <a
                            href={`https://carwarrantyprices.com/generic/Save-big-on-car-protection-plans${
                                queryString ? `?${queryString}` : ""
                            }`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            potential savings
                        </a>{" "}
                        a comprehensive auto warranty can offer.
                    </p>
                    <p>
                        In fact, if you've been dipping into your savings for
                        auto repairs more than a couple of times a year, there's
                        a good chance you're spending more than you should. But
                        here's the catch: many don't realize the potential
                        savings at their fingertips.
                    </p>
                    <p>
                        Thanks to new and{" "}
                        <a
                            href={`https://carwarrantyprices.com/generic/find-specialized-auto-warranty-providers-in-your-area${
                                queryString ? `?${queryString}` : ""
                            }`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            specialized auto warranty providers,
                        </a>{" "}
                        many car owners are discovering they can save
                        significantly in the long run. This isn't about simple
                        coverage—it's about investing in your vehicle's
                        longevity and peace of mind. And the best part? Finding
                        out just how much you can save is easier than ever.
                    </p>
                </>
            ),
            buttonTitle:
                "Discover Potential Savings With Auto Warranties Today",
            href: `https://carwarrantyprices.com/generic/discover-the-potential-savings-with-car-warranty-today${
                queryString ? `?${queryString}` : ""
            }`,
        },
        {
            title: "6. Navigating Life Insurance in Your Golden Years: What US Seniors Need to Know",
            img: "/thriftyadvice/assets/images/lifeInsurance.jpg",
            content: (
                <>
                    <p>
                        Many seniors wonder if life insurance is necessary
                        during retirement. The answer lies in your individual
                        circumstances and the financial protection you wish to
                        leave for your family. Life insurance can provide
                        financial security for loved ones by covering funeral
                        expenses and outstanding debts.
                    </p>
                    <p>
                        There are many misconceptions about life insurance,
                        especially when it comes to seniors. Some believe it's
                        too expensive or difficult to obtain as they age.
                        However,{" "}
                        <a
                            href={`https://insurancesupersaver.com/life${
                                queryString ? `?${queryString}` : ""
                            }`}
                        >
                            many affordable options are available,
                        </a>{" "}
                        and policies can often be tailored to meet your specific
                        needs.
                    </p>
                </>
            ),
            buttonTitle:
                "Secure Your Legacy with Our Expert Life Insurance Plans",
            href: `https://insurancesupersaver.com/life/generic/senior%27s-ultimate-guide-for-life-insurance-policies${
                queryString ? `?${queryString}` : ""
            }`,
        },
    ];
    const month = monthsArr[new Date().getMonth() - 1];
    const year = new Date().getFullYear();
    return (
        <section>
            <h2 className="font-bold lg:text-5xl text-3xl lg:leading-[55px] text-black ">
                Seniors' Guide to Financial Peace: Navigating Debt Relief
                Solutions
            </h2>
            <span className="text-[#bababa] mt-4 block">
                by{" "}
                <span className="font-bold lg:text-lg text-base text-[#000]">
                    David I. Morin
                </span>{" "}
                {month}, {year}
            </span>
            <div className={`relative  mt-2 `}>
                <Image
                    src="/thriftyadvice/assets/images/mainImg.jpg"
                    layout="responsive"
                    width={900}
                    height={504}
                    alt="Image"
                />
            </div>
            <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                Were you born before 1965? If so, your financial future could be
                on the line, and you can't afford to miss out! The transition
                from a lifetime of hard work to a well-earned retirement can be
                a make-or-break moment for your economic well-being. But don't
                worry; we've got five must-do suggestions to help you secure
                your financial horizon.
            </p>
            <div className="lg:mb-[127px] mb-[60px]">
                {mainCategoriesArray.map((step, index) => (
                    <MainCategoryComponent key={index} step={step} />
                ))}
            </div>
        </section>
    );
}
